/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

/* Quill */
@import '~quill/dist/quill.snow.css';

@import 'ngx-toastr/toastr';
@import 'angular-calendar/scss/angular-calendar.scss';

// Swiper
// @import "node_modules/swiper/swiper";
// @import "node_modules/swiper/modules/navigation/navigation";
// @import "node_modules/swiper/modules/pagination/pagination";
// @import "node_modules/swiper/modules/thumbs/thumbs";
