/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Dialogs
.dialog-no-padding .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
}

// Tables
.mat-mdc-header-row {
    position: sticky;
    top: 0;
    z-index: 45;
    background: white !important;
}

.mat-mdc-row:hover .mat-mdc-cell {
    @apply bg-slate-100;
}

// Tables
.mat-mdc-table {

    .mat-mdc-row {
        position: relative;
        // cursor: pointer;
        min-height: 60px !important;
        height: 60px;
    }
}

.mat-horizontal-content-container {
    padding: 0 0 0 0 !important;
}

// TO DELETE!!!!

table {
    width: 100%;
}

tr.example-detail-row {
    height: 0;
}

tr.example-element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
}

tr.example-element-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-element-row td {
    border-bottom-width: 0;
}

.example-element-detail {
    overflow: hidden;
    display: flex;
}

.example-element-diagram {
    min-width: 80px;
    border: 2px solid black;
    padding: 8px;
    font-weight: lighter;
    margin: 8px 0;
    height: 104px;
}

.example-element-symbol {
    font-weight: bold;
    font-size: 40px;
    line-height: normal;
}

.example-element-description {
    padding: 16px;
}

.example-element-description-attribution {
    opacity: 0.5;
}
